//scripts
$.ajaxSetup({
    cache: false
});

//Toggles hamburger menu
function toggleMenu() {
    $('.mobile-button').toggle();
    $('.mobile-close').toggle();

    var hidden = $('.mobile-nav-menu').css("display") === "none" ? true : false;
    if (hidden)
        $('.mobile-nav-menu').css("display", "flex");
    else
        $('.mobile-nav-menu').hide();
}


$(document).ready(function(){
    $('#SubmitButton').click(createEmail);

});

//Popup functionality
function showPopup(sender) {
    var popup = $(sender).data('card');
    $('.overlayFilter').empty().load('partials/' + popup + '.html').css('display', 'flex');
    $('body').css("overflow", "hidden");
}
//Popup functionality
function showArticle(sender) {
    var popup = $(sender).data('card');
    $('.overlayFilterArticle').empty().load('partials/posts/' + popup + '.html').css('display', 'flex');
    $('body').css("overflow", "hidden");
}
//Popup functionality
function showService(sender) {
    var popup = $(sender).data('card');
    $('.overlayFilterService').empty().load('partials/service/' + popup + '.html').css('display', 'flex');
    $('body').css("overflow", "hidden");
}

//smooth scroll
$(document).ready(function(){
    // Add smooth scrolling
    $("a").on('click', function(event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function(){
                window.location.hash = hash;
            });
        }
    });
});



// Contact Form functionality
function createEmail (e) {

    e.preventDefault();

    $(e.sender).attr('disabled', true);

    var funcParams = {
        FirstName: $('#FirstName').val(),
        LastName: $('#LastName').val(),
        Email: $('#Email').val(),
        Phone: $('#Phone').val(),
        Message: $('#Message').val(),
        'g-recaptcha-response': grecaptcha.getResponse()
    };

    var jsonData = funcParams;

    showProgressBar ();

    $.ajax({
        url: settings.Url + 'Contact/CreateForm/',
        data: jsonData,
        type: 'POST',
        success: function (data) {
            console.log(data);
            if (data.success) {
                $('#SubmitButton').attr('disabled', true);

                hideProgressBar ();

                showCheck ();
                setTimeout(function () {
                    hideCheck();
                }, 5000);

            } else {
                hideProgressBar ();

                // showRetry ();
                showCheck ();

                setTimeout(function () {
                    //hideRetry();
                    hideCheck();
                }, 5000);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.status);

            hideProgressBar ();

            showCheck ();
            //showRetry ();

            setTimeout(function () {
                //hideRetry();
                hideCheck();
            }, 5000);
        }
    })
}

// Progress Indicator
function showProgressBar () {
    $('.contact-form-progress').fadeIn();

    $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });

}
function hideProgressBar () {
    $('.contact-form-progress').hide();

    // $('html, body').css({
    //     overflow: 'auto',
    //     height: 'auto'
    // });
}
// Retry Message
function showRetry () {
    $('.contact-form-retry').fadeIn();

    $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });
}
function hideRetry () {
    $('.contact-form-retry').hide();

    $('html, body').css({
        overflow: 'auto',
        height: 'auto'
    });
}
// Retry Message
function showCheck () {
    $('.contact-form-success').fadeIn();

    $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });
}
function hideCheck () {
    $('.contact-form-success').hide();

    $('html, body').css({
        overflow: 'auto',
        height: 'auto'
    });
}
